<template>
  <div>
    <el-scrollbar
      wrap-style="max-height: 400px;padding:13px;"
      style="border-width: 0px"
    >
      <div
        class="d-flex flex-column justify-content-center"
        v-for="column in newRepeatableTemplateData"
        :key="column.label"
        :prop="column.key"
      >
        <div v-if="column.inputType == 'DATE'">
          <h5 class="kanban-card-content">
            {{ column.label + " " + (column.is_primary ? "*" : "") }}
          </h5>
          <el-date-picker
            placeholder="Select date"
            style="width: 100%"
            v-model="selectedRow[column.key]"
            :format="
              getDefaultDateFormat &&
              getDefaultDateFormat.includes('Do') == false
                ? getDefaultDateFormat
                    .replace('YYYY', 'yyyy')
                    .replace('DD', 'dd')
                : 'MM-dd-yyyy'
            "
          >
          </el-date-picker>
        </div>
        <div
          v-else-if="
            column.inputType == 'SELECT' || column.inputType == 'MULTI_SELECT'
          "
        >
          <h5 class="kanban-card-content">
            {{ column.label + " " + (column.is_primary ? "*" : "") }}
          </h5>
          <el-select
            placeholder="Please select..."
            :clearable="true"
            :filterable="true"
            :multiple="column.inputType == 'MULTI_SELECT' ? true : false"
            :allow-create="true"
            :default-first-option="true"
            v-model="selectedRow[column.key]"
            style="width: 100%"
            :disabled="
              (column.inputType == 'SELECT' ||
                column.inputType == 'MULTI_SELECT') &&
              column.options &&
              !column.options.length
            "
          >
            <el-option
              v-for="op in column.options"
              :key="op"
              :label="op"
              :value="op"
            ></el-option>
          </el-select>
        </div>
        <div v-else-if="column.inputType == 'CHECKBOX'">
          <h5 class="kanban-card-content">
            {{ column.label + " " + (column.is_primary ? "*" : "") }}
          </h5>
          <el-checkbox v-model="selectedRow[column.key]"> </el-checkbox>
        </div>
        <div v-else-if="column.inputType == 'ENTITY'">
          <h5 class="kanban-card-content">
            {{ column.label + " " + (column.is_primary ? "*" : "") }}
          </h5>
          <EntityExecute
            :data="column"
            :form="selectedRow"
            :is-view="false"
            :fieldsData="newRepeatableTemplateData"
            :fromRepeatable="true"
            @entityDataUpdated="emitDataToEntityVariables"
            @clearEntityFields="emitUnsetEntityVariables"
            :document_id="document_id ? document_id : null"
            :company_id="company_id ? company_id : null"
          ></EntityExecute>
        </div>
        <div v-else-if="column.inputType == 'CURRENCY'">
          <h5 class="kanban-card-content">
            {{ column.label + " " + (column.is_primary ? "*" : "") }}
          </h5>
          <currency-input
            v-model="selectedRow[column.key]"
            class="field-textarea currency-input"
            :currency="
              column.validations && column.validations.currency
                ? column.validations.currency
                : 'USD'
            "
          />
        </div>
         <div v-else-if="column.inputType == 'CHECKBOX_GROUP'">
          <h5 class="kanban-card-content">
            {{ column.label + " " + (column.is_primary ? "*" : "") }}
          </h5>
          <checkboxGroupExecute
            :data="column"
            :form="selectedRow"
            :fromRepeatable="true"
            :document_id="document_id ? document_id : null"
            :company_id="company_id ? company_id : null"
          ></checkboxGroupExecute>
        </div>
        <div v-else-if="column.inputType == 'FORMULA'">
          <h5 class="kanban-card-content">
            {{ column.label + " " + (column.is_primary ? "*" : "") }}
          </h5>
          <FormulaExecute
            :data="column"
            :form="selectedRow"
            :fromRepeatable="true"
            :document_id="document_id ? document_id : null"
            :company_id="company_id ? company_id : null"
          ></FormulaExecute>
        </div>
        <div v-else>
          <h5 class="kanban-card-content">
            {{ column.label + " " + (column.is_primary ? "*" : "") }}
          </h5>
          <el-input type="text" v-model="selectedRow[column.key]"></el-input>
        </div>
        <br />
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
import CurrencyInput from "../../vue-weblineindia-currency-input/src/component";
export default {
  props: [
    "newRepeatableTemplateData",
    "form",
    "selectedRow",
    "getDefaultDateFormat",
    "company_id",
    "document_id",
  ],
  components: {
    CurrencyInput,
    FormulaExecute: () =>
      import("../../templates/formComponentsExecute/FormulaExecute.vue"),
    EntityExecute: () =>
      import("../../templates/formComponentsExecute/EntityExecute"),
      checkboxGroupExecute: () => import("../../templates/formComponentsExecute/CheckBoxGroupExecute.vue")
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    emitUnsetEntityVariables(parent) {
      this.newRepeatableTemplateData.forEach((field) => {
        if (field.relationship_key == parent.key) {
          this.$set(this.selectedRow, field.key, "");
        }
      });
    },
    emitDataToEntityVariables(data, parent, label) {
      if (label) {
        this.$set(this.selectedRow, parent.key + "/name", label);
      }
      this.newRepeatableTemplateData.forEach((field) => {
        if (field.relationship_key == parent.key && data.entityData) {
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (
              data.entityData &&
              data.entityData[templateId] &&
              data.entityData[templateId][key]
            ) {
              value = data.entityData[templateId][key];
            }
            console.log("value", value);
            if (this.selectedRow[field.key]) {
              this.selectedRow[field.key] = value;
            } else {
              this.$set(this.selectedRow, field.key, value);
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.kanban-card {
  width: 420px;
  margin: 10px;
  display: inline-block;
  border: 1px solid #cfd4d1;
}
.kanban-card-column {
  // display: inline-block;
  word-wrap: break-word;
  margin: 10px;
  padding: 5px;
}
.kanban-card-content {
  font-family: "system-ui";
  font-size: 16px;
}
.kanban-footer {
  border-top: 1px solid #cfd4d1;
  height: 2vh;
  padding: 2px;
  text-align: right;
}
.kanban-footer-button {
  color: #f73620;
}
.field-textarea {
  &.currency-input {
    width: 95% !important;

    height: 40px !important;

    border: 1px solid #dcdfe6;

    // border-color: transparent !important;

    background: transparent !important;

    border-radius: 4px;
  }

  &.currency-input:focus {
    // border: none !important;

    outline: none !important;
  }
}
</style>
